import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'
import firefliesPurpleFragmentShader from './shaders/fireflies/fragmentPurple.glsl'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { MathUtils } from 'three'
import gsap from 'gsap'
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

var isiOS = false;
var is_safari = false;
let user_agent = null;
if(navigator != undefined && navigator.userAgent != undefined) {
    user_agent = navigator.userAgent.toLowerCase();
    is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    if(/ipad|iphone|ipod/.test(user_agent) || is_safari) {
        isiOS = true;
    }
}
/**
 * Cursor
 */
 const cursor = {}
 cursor.x = 0
 cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

var screenType = 0;
if((window.innerWidth / window.innerHeight) < 1.3)
    screenType =  2;
else if((window.innerWidth / window.innerHeight) < 1.45)
    screenType =  1;
else
    screenType =  0;

/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('./draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

const textureLoader = new THREE.TextureLoader();
const texBackground = textureLoader.load('./models/BackgroundCaves_Bake.jpg')
texBackground.flipY = false;
const texEntrance = textureLoader.load('./models/MineEntrance_Baked.jpg')
texEntrance.flipY = false;
const texGround = textureLoader.load('./models/Ground_Bake.jpg')
texGround.flipY = false;
const texCircles = textureLoader.load('./models/Circles_Bake.jpg')
texCircles.flipY = false;
const texCrystals = textureLoader.load('./models/Crystals_Bake.jpg')
texCrystals.flipY = false;
const texColumn = textureLoader.load('./models/RockColumns_Bake.jpg')
texColumn.flipY = false;
const texCave1 = textureLoader.load('./models/RocksCave1_Bake.jpg')
texCave1.flipY = false;
const texCave2 = textureLoader.load('./models/RocksCave2_Bake.jpg')
texCave2.flipY = false;
const texCave3 = textureLoader.load('./models/RocksCave3_Bake.jpg')
texCave3.flipY = false;
const texTop1 = textureLoader.load('./models/RocksTop1_Bake.jpg')
texTop1.flipY = false;
const texTop2 = textureLoader.load('./models/RocksTop2_Bake.jpg')
texTop2.flipY = false;
const texTop3 = textureLoader.load('./models/RocksTop3_Bake.jpg')
texTop3.flipY = false;
let dualMesh = null;
const backgroundMaterial = new THREE.MeshBasicMaterial({
    map: texBackground,
})
const entranceMaterial = new THREE.MeshBasicMaterial({
    map: texEntrance,
})
const groundMaterial = new THREE.MeshBasicMaterial({
    map: texGround,
})
const circlesMaterial = new THREE.MeshBasicMaterial({
    map: texCircles,
})
const crystalsMaterial = new THREE.MeshBasicMaterial({
    map: texCrystals,
})
const columnMaterial = new THREE.MeshBasicMaterial({
    map: texColumn,
})
const cave1Material = new THREE.MeshBasicMaterial({
    map: texCave1,
})
const cave2Material = new THREE.MeshBasicMaterial({
    map: texCave2,
})
const cave3Material = new THREE.MeshBasicMaterial({
    map: texCave3,
})
const top1Material = new THREE.MeshBasicMaterial({
    map: texTop1,
})
const top2Material = new THREE.MeshBasicMaterial({
    map: texTop2,
})
const top3Material = new THREE.MeshBasicMaterial({
    map: texTop3,
})
const backgroundMaterialW = new THREE.MeshBasicMaterial({
    map: texBackground,
    wireframe: true
})
const entranceMaterialW = new THREE.MeshBasicMaterial({
    map: texEntrance,
    wireframe: true
})
const groundMaterialW = new THREE.MeshBasicMaterial({
    map: texGround,
    wireframe: true
})
let mixer = null
let action = null

let mixerModel = null
let actionModel = null
const pressButton = document.getElementById("press");
const skipButton = document.getElementById("skipress");
const welcomeLegend = document.getElementById("legend");
const logo = document.getElementById("logo")
const arrow = document.querySelector('.arrow');
var finished = false;
let cameraDirector = null
var cameraWorldPosition = new THREE.Vector3();
var cameraDirectorForward = new THREE.Vector3();
let secondMesh = null;
let cameraRotation = null;
let lastCameraPosition = null;

var fader = document.querySelector('.fader');
var loadingBarElement = document.querySelector('.loading-bar')
pressButton.classList.add('invisible')
skipButton.classList.add('invisible')

gltfLoader.load(
    './models/MiningverseIntro.glb',
    (gltf) =>
    {
        //gltf.scene.scale.set(0.5, 0.5, 0.5)
        //gltf.scene.rotateY( -Math.PI*0.5)
        secondMesh = gltf.scene.clone();
        gltf.scene.traverse((child)=>
        {                
            switch(child.name)
            {
                case "Ground":
                    child.material = groundMaterial;
                    break;   
               case "MineEntrance":
                    child.material = entranceMaterial;
                    break;
                case "BackgroundCaves":
                    child.material = backgroundMaterial;
                    break;
                case "Circle3":
                    child.material = circlesMaterial;
                case "Circle1":
                    child.material = circlesMaterial;
                    break;
                case "Circle2":
                    child.material = circlesMaterial;
                    break;
                case "RocksCave1":
                    child.material = cave1Material;
                    break;
                case "RocksCave2":
                    child.material = cave2Material;
                    break;
                case "RocksCave3":
                    child.material = cave3Material;
                    break;
                case "RockColumnsBaked":
                    child.material = columnMaterial;
                    break;
                case "RocksTop1":
                    child.material = top1Material;
                    break;
                case "RocksTop2":
                    child.material = top2Material;
                    break;
                case "RocksTop3":
                    child.material = top3Material;
                    break;
                case "Crystals":
                    child.material = crystalsMaterial;
                    break;
                case "CameraGuide":
                    cameraDirector = child;
                    cameraDirector.getWorldPosition(cameraWorldPosition);
                    //camera.position.set(cameraDirector.position.x, cameraDirector.position.y, cameraDirector.position.z)
                    camera.position.set(cameraWorldPosition.x, cameraWorldPosition.y, cameraWorldPosition.z)
                    cameraDirector.getWorldDirection( cameraDirectorForward );
                    camera.lookAt(cameraDirectorForward);
                        break;
            }                        
        })
        scene.add(gltf.scene)
        // Animation
        mixerModel = new THREE.AnimationMixer(gltf.scene)
        actionModel = mixerModel.clipAction(gltf.animations[0])        
        actionModel.play()
        const actionModel1 = mixerModel.clipAction(gltf.animations[1])
        actionModel1.play()
        const actionModel2 = mixerModel.clipAction(gltf.animations[2])
        actionModel2.play()
    }
)
gltfLoader.load(
    './models/MiningverseIntroCamera.glb',
    (gltf) =>
    {   
        gltf.scene.traverse((child)=>
        {                
            switch(child.name)
            {
                case "CameraGuide":
                    cameraDirector = child;
                    cameraDirector.getWorldPosition(cameraWorldPosition);
                    //camera.position.set(cameraDirector.position.x, cameraDirector.position.y, cameraDirector.position.z)
                    camera.position.set(cameraWorldPosition.x, cameraWorldPosition.y, cameraWorldPosition.z)
                    cameraDirector.getWorldDirection( cameraDirectorForward );
                    camera.lookAt(cameraDirectorForward);
                        break;
            }                        
        })
        
        
        scene.add(gltf.scene)        
        // Animation
        mixer = new THREE.AnimationMixer(gltf.scene)
        action = mixer.clipAction(gltf.animations[0])
        action.loop = THREE.LoopOnce;
        action.clampWhenFinished = true;

        mixer.addEventListener( 'finished', ()=>{
            welcomeLegend.classList.add('visible')
            logo.classList.add('visible');
            cameraRotation = camera.rotation;
            lastCameraPosition = camera.position.clone();
            //finished = true;
            //arrow.classList.add('visible');
            window.open("https://home.novaminingverse.com", "_self")
        } ); 
    }
)
pressButton.addEventListener("click",()=>{
    action.play()
    pressButton.classList.add('invisible')
},true)
skipButton.addEventListener("click",()=>{
    window.open("https://home.novaminingverse.com", "_self")
},true)

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.CircleGeometry(10, 30),
    new THREE.MeshStandardMaterial({
        color: '#964B00',
        metalness: 0,
        roughness: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
//scene.add(floor)

/**
 * Fireflies
 */
// Geometry
const firefliesGeometry = new THREE.BufferGeometry()
const firefliesCount = 40
const positionArray = new Float32Array(firefliesCount * 3)
const scaleArray = new Float32Array(firefliesCount)

for(let i = 0; i < firefliesCount; i++)
{
    positionArray[i * 3 + 0] = (Math.random() - 0.5) * 4
    positionArray[i * 3 + 1] = Math.random() * 2
    positionArray[i * 3 + 2] = (Math.random() - 0.5) * 4

    scaleArray[i] = Math.random() * 2.5
}

firefliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
firefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

// Material
const firefliesMaterial = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 100 }
    },
    vertexShader: firefliesVertexShader,
    fragmentShader: firefliesFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false
})
// Points
const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)
scene.add(fireflies)

const firefliesGeometryD = new THREE.BufferGeometry()
const firefliesCountD = 350
const positionArrayD = new Float32Array(firefliesCount * 3)
const scaleArrayD = new Float32Array(firefliesCount)

for(let i = 0; i < firefliesCountD; i++)
{
    positionArrayD[i * 3 + 0] = (Math.random() - 0.5) * 30
    positionArrayD[i * 3 + 1] = -12 + (Math.random() * 10)
    positionArrayD[i * 3 + 2] = (Math.random() - 0.5) * 15

    scaleArrayD[i] = Math.random() * 10.5
}

firefliesGeometryD.setAttribute('position', new THREE.BufferAttribute(positionArrayD, 3))
firefliesGeometryD.setAttribute('aScale', new THREE.BufferAttribute(scaleArrayD, 1))

// Material
const firefliesMaterialD = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 100 }
    },
    vertexShader: firefliesVertexShader,
    fragmentShader: firefliesPurpleFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false
})
// Points
const firefliesD = new THREE.Points(firefliesGeometryD, firefliesMaterialD)
scene.add(firefliesD)
/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(- 5, 5, 0)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
    // Update effect composer
    effectComposer.setSize(sizes.width, sizes.height)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(60, sizes.width / sizes.height, 0.01, 1000)
camera.position.set(0, 0, 0)
scene.add(camera)

// Controls
/*const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.6, 0)
controls.enableDamping = true
controls.enablePan = false;
controls.enableZoom = false;

//controls.maxAzimuthAngle =  Math.PI *2;
//controls.minAzimuthAngle =  -Math.PI * 2;
controls.maxPolarAngle = Math.PI * 0.43;
controls.minPolarAngle = Math.PI * 0.2;
controls.rotateSpeed = 0.5;*/

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
})
renderer.shadowMap.enabled = true
//renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(window.devicePixelRatio)
/**
 * Post processing
 */
 const renderTarget = new THREE.WebGLRenderTarget(
    800,
    600,
    {
        samples: 2
    }
)

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(window.devicePixelRatio)
effectComposer.setSize(sizes.width, sizes.height)

// Render pass
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)
// Unreal Bloom pass
const unrealBloomPass = new UnrealBloomPass()
unrealBloomPass.enabled = true
effectComposer.addPass(unrealBloomPass)

unrealBloomPass.strength = 0.5
unrealBloomPass.radius = 1.41
unrealBloomPass.threshold = 0.25
/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
let tmpRotation = null;
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    firefliesMaterial.uniforms.uTime.value = elapsedTime
    firefliesMaterialD.uniforms.uTime.value = elapsedTime
    // Model animation
    if(mixer)
    {
        mixer.update(deltaTime)
        if(!finished)
        {
        cameraDirector.getWorldPosition(cameraWorldPosition);
        camera.position.set(cameraWorldPosition.x, cameraWorldPosition.y, cameraWorldPosition.z)
        /*var parallaxX = cursor.x * 2.5;
        var parallaxY = -cursor.y * 2.5;
        camera.position.x += (parallaxX *deltaTime);
        camera.position.y += (parallaxY * deltaTime);*/
        cameraDirector.getWorldDirection( cameraDirectorForward );
        //camera.lookAt(cameraDirectorForward);
        camera.rotation.copy(cameraDirector.rotation);
        }
        else if(screenType < 2)
        {
            var parallaxX = cursor.x * 30;
            var parallaxY = -cursor.y * 30;
            camera.position.x = lastCameraPosition.x + (parallaxX *deltaTime);
            camera.position.y = lastCameraPosition.y + (parallaxY * deltaTime);
            /*camera.position.x += (parallaxX - camera.position.x) * 5 * deltaTime
            camera.position.y += (parallaxY - camera.position.y) * 5 * deltaTime*/
            /*parallaxX = MathUtils.clamp(parallaxX, -0.06, 0.06);
            parallaxY = MathUtils.clamp(parallaxY, -0.05, 0.05);*/
            //console.log(cameraRotation);
            //tmpRotation = cameraRotation;
            //tmpRotation.x +=parallaxX;
            //tmpRotation.y += parallaxY;
            /*const a = new THREE.Euler( parallaxY, parallaxX, 0, 'XYZ' );
            camera.setRotationFromEuler(a);*/
        }
    }
    if(mixerModel)
    {
        mixerModel.update(deltaTime)        
    }    
    // Update controls
    //controls.update()

    // Render
    if(isiOS) 
    {
        renderer.render(scene, camera)
    }
    else
    {
        effectComposer.render();
    }
    
    

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()


        var alpha = {t:1};
        var inverse = 0.3;
        loadingBarElement.style.transform = `scaleX(${inverse})`
        gsap.to(alpha, { 
            duration: 1.5, 
            t: 0, 
            delay:0.3,
            onStart: ()=>{      
                          
            },
            onUpdate: ()=>{
                fader.style.opacity = '${alpha.t}'
                inverse -= alpha;
                loadingBarElement.style.transform = `scaleX(${alpha})`
            },
            onComplete: ()=>{
                
                fader.classList.add('invisible')  
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''    
                pressButton.classList.remove('invisible')    
                skipButton.classList.remove('invisible')        
            }
        }).play();


